import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';

import Layout from 'components/shared/Layout';
import SEO from 'components/shared/Seo';

const BannerCaseStudy = loadable(() =>
  import('../components/organisms/BannerCaseStudy')
);
const BannerCaseStudyImage = loadable(() =>
  import('../components/organisms/BannerCaseStudyImage')
);
const NextWorkCaseStudy = loadable(() =>
  import('../components/organisms/NextWorkCaseStudy')
);
const DescriptionIndustry = loadable(() =>
  import('../components/organisms/DescriptionIndustry')
);

const CaseStudyTemplate = ({ data }) => {
  const { allWordpressWpAppCaseStudy, allWordpressWpAppWorkTag } = data;

  const currentPageData = allWordpressWpAppCaseStudy.edges[0].node;

  const tags = allWordpressWpAppWorkTag.edges;

  const currentPageWidgetsData =
    currentPageData.acf.blocks_app_case_study || [];

  const breadcrumbsData = {
    data: {
      title: currentPageData.title,
      path: currentPageData.path,
    },
    parent: { title: 'Work', path: '/works' },
  };

  let pageComponents = [];

  currentPageWidgetsData.forEach((widget, index) => {
    switch (widget.__typename) {
      case 'WordPressAcf_gallery':
        const Gallery = loadable(() =>
          import('../components/organisms/Gallery')
        );
        return pageComponents.push(
          <Gallery data={widget} key={index} caseStudy={true} />
        );

      case 'WordPressAcf_case_study_text':
        const TextCaseStudyNew = loadable(() =>
          import('../components/organisms/TextCaseStudyNew')
        );
        return pageComponents.push(
          <TextCaseStudyNew data={widget} key={index} />
        );

      case 'WordPressAcf_quote':
      case 'WordPressAcf_highlighted_text':
        const HighlightedQuoteText = loadable(() =>
          import('../components/organisms/HighlightedQuoteText')
        );
        return pageComponents.push(
          <HighlightedQuoteText data={widget} key={index} />
        );

      case 'WordPressAcf_code_block':
        const CodeBlock = loadable(() =>
          import('../components/organisms/CodeBlock')
        );
        return pageComponents.push(<CodeBlock data={widget} key={index} />);

      default:
        return;
    }
  });

  return (
    <Layout pageWidgetsData={currentPageWidgetsData} footerType={'full'}>
      <SEO yoastMeta={currentPageData.yoast_meta} url={currentPageData.link} />
      <BannerCaseStudy
        data={currentPageData}
        breadcrumbsData={breadcrumbsData}
      />
      <DescriptionIndustry data={currentPageData} tagsData={tags} small />
      <BannerCaseStudyImage data={currentPageData} />
      {pageComponents}
      <NextWorkCaseStudy data={currentPageData} />
    </Layout>
  );
};

export default CaseStudyTemplate;

export const query = graphql`
  query($id: String!) {
    allWordpressWpAppCaseStudy(filter: { id: { eq: $id } }) {
      ...AllWordpressWpAppCaseStudyFragment
    }
    allWordpressWpAppWorkTag {
      ...AllWordpressWpAppWorkTagFragment
    }
  }
`;
